@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
}

/* top Bar */

/*  ===============================================
    TopBar
------------------------*/

.ttm-bgcolor-darkgrey {
  background-color: #252525;
}
.ttm-textcolor-white {
  color: white;
}
.ttm-topbar-wrapper {
  line-height: 49px;
  height: 50px;
}
.header-btn {
  background-color: #bf9456;
}
.header-btn .ttm-btn.ttm-btn-size-md {
  padding: 16px 25px 14px 25px;
  font-size: 13px;
  vertical-align: top;
  line-height: 20px;
  font-weight: 500;
  transition: all 0.5s;
  display: block;
}
.header-btn .ttm-btn.ttm-btn-size-md:before {
  content: none;
}
.ttm-topbar-wrapper .top-contact.text-left {
  position: relative;
  float: left;
  line-height: 50px;
}
.top-contact li {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.top-contact li:first-child {
  padding-left: 0px !important;
}
.top-contact li strong {
  font-weight: 500;
}
.top-contact {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
  display: inline-block;
  position: relative;
}
.ttm-topbar-content .top-contact li:before {
  content: "";
  height: 18px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.09);
  display: block;
  position: absolute;
  right: -3px;
  top: 17px;
}
.ttm-topbar-content .top-contact li:last-child:before {
  width: 0px;
}
.topbar-right .top-contact.ttm-highlight-right li:last-child:after {
  content: unset;
}
.ttm-topbar-wrapper .ttm-social-links-wrapper {
  margin: 0;
  position: relative;
  padding-left: 5px;
  padding-right: 15px;
}
.ttm-topbar-wrapper .social-icons li {
  margin: 0 3px;
}
.ttm-topbar-wrapper .social-icons li > a {
  display: block;
  border: none;
  width: 17px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  font-weight: 500;
  background-color: transparent;
  text-align: center;
}
.ttm-topbar-wrapper .social-icons li > a:hover {
  color: #bf9456 !important;
}
.ttm-topbar-content .topbar-right .list-inline {
  display: inline-block;
  line-height: 48px;
}
.top-contact.ttm-highlight-left {
  margin-right: 15px;
  padding-right: 5px;
}
.top-contact.ttm-highlight-right {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
}
.top-contact.ttm-highlight-left:after,
.top-contact.ttm-highlight-right:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 5000px;
  height: 100%;
}
.top-contact.ttm-highlight-right:after {
  right: 0;
}
.top-contact.ttm-highlight-right:after {
  left: 0;
}
.top-contact strong {
  font-weight: 600;
}
.top-contact i {
  margin-right: 7px;
  font-size: 14px;
  width: 15px;
  height: 15px;
  text-align: center;
  color: #bf9456;
}
.text-left {
  text-align: left !important;
}

.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-skincolor,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-dark:hover,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-skincolor,
.ttm-tabs.tabs-style-01 ul.tabs li.active,
.ttm-tabs.tabs-style-01 ul.tabs li:hover,
#site-header-menu #site-navigation .menu ul li:hover > ul,
.widget .tagcloud a:hover,
.ttm-blog-classic .post-meta,
.ttm-btn.ttm-btn-color-darkgrey:hover,
.ttm-btn.ttm-btn-color-white:hover,
.featured-icon-box.icon-align-top-content.style1 .ttm-border-seperator:before,
.featured-icon-box.style8 .ttm-icon.ttm-icon_element-size-xs,
.featured-imagebox-team.style1 .featured-content {
  border-color: #bf9456;
}
.ttm-bgcolor-skincolor,
.ttm-btn-bgcolor-skincolor,
.ttm-bgcolor-skincolor > .ttm-bg-layer,
.ttm-bgcolor-skincolor > .ttm-bg-layer > .ttm-col-wrapper-bg-layer-inner,
.ttm-icon_element-fill.ttm-icon_element-color-skincolor,
.ttm-btn-style-fill.ttm-btn-color-skincolor,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:not(.btn-inline):hover:after,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-dark:not(.btn-inline):hover:after,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-grey:not(.btn-inline):after,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-grey:not(.btn-inline):after,
.ttm-bgcolor-darkgrey .tooltip:after,
.ttm-bgcolor-darkgrey [data-tooltip]:after,
.ttm-bgcolor-grey .tooltip:after,
.ttm-bgcolor-grey [data-tooltip]:after,
.ttm-tabs.tabs-style-01 ul.tabs li.active a,
.ttm-tabs.tabs-style-01 ul.tabs li:hover a,
.ttm-tabs.tabs-style-01 ul.tabs li.active,
.ttm-blogbox-footer-readmore,
.ttm-blog-classic .ttm-postcategory .ttm-meta-line a,
.widget .tagcloud a:hover,
.footer .social-icons li > a:hover,
.ttm-pagination .page-numbers.current,
.ttm-pagination .page-numbers:hover,
.featured-imagebox-services.style2:hover
  .featured-content.ttm-bgcolor-darkgrey
  a.ttm-icon,
.tooltip:after,
[data-tooltip]:after,
#totop.top-visible,
.ttm-blog-classic .post-meta .ttm-meta-line:not(:last-child):after,
.ttm-blog-single-content .post-meta .ttm-meta-line:not(:last-child):after,
.team-media-block ul li a:hover,
.ttm-btn:not(.btn-inline):before,
.ttm-btn.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:hover:before,
.ttm-list.ttm-list-style-icon.style1 li:after,
.ttm-list.ttm-list-style-icon.style3 li:before,
.featured-icon-box.icon-align-top-content.style1 .ttm-border-seperator:after,
.featured-icon-box.icon-align-top-content.style1 .ttm-service-icon-dots:before,
.featured-imagebox-services.style1 a.di_link,
.featured-thumbnail .featured-thumbnail-inner:before,
.ttm-progress-bar .progress-bar,
.testimonials.style1 .testimonial-img:before,
.testimonials.style2 .testimonial-img:before,
.widget.widget-nav-menu ul li:hover a,
.widget.widget-nav-menu ul li.active a,
#site-header-menu #site-navigation .menu > ul > li ul a:before {
  background-color: #bf9456;
}
.ttm-textcolor-white,
.ttm-bgcolor-skincolor a,
.ttm-textcolor-white a,
.ttm-btn-color-white,
.ttm-icon_element-color-white,
.ttm-bgcolor-skincolor i,
.ttm-textcolor-white h1,
.ttm-textcolor-white h2,
.ttm-textcolor-white h3,
.ttm-textcolor-white h4,
.ttm-textcolor-white h5,
.ttm-textcolor-white h6,
.ttm-textcolor-white a,
.ttm-bgcolor-skincolor h1,
.ttm-bgcolor-skincolor h2,
.ttm-bgcolor-skincolor h3,
.ttm-bgcolor-skincolor h4,
.ttm-bgcolor-skincolor h5,
.ttm-bgcolor-skincolor h6,
.ttm-bgcolor-skincolor a,
.ttm-bgcolor-darkgrey h1,
.ttm-bgcolor-darkgrey > h2,
.ttm-bgcolor-darkgrey h3,
.ttm-bgcolor-darkgrey h4,
.ttm-bgcolor-darkgrey > h5,
.ttm-bgcolor-darkgrey h6,
.ttm-bgcolor-darkgrey a,
.ttm-textcolor-white .ttm-social-links-wrapper ul li a,
.ttm-icon_element-fill.ttm-icon_element-color-skincolor,
.ttm-icon_element-fill.ttm-icon_element-color-darkgrey,
.ttm-btn-style-fill.ttm-btn-color-skincolor,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-grey:hover,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-dark:hover,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:hover,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-white:hover,
.footer h4.ttm-textcolor-white,
.page-title-heading h1,
.breadcrumb-wrapper span,
.breadcrumb-wrapper span a,
.ttm-tabs.tabs-style-01 ul.tabs li.active a,
.ttm-tabs.tabs-style-01 ul.tabs li:hover a,
.ttm-blogbox-footer-readmore,
.ttm-blog-classic .ttm-postcategory .ttm-meta-line a,
.featured-imagebox-services.style2:hover
  .featured-content.ttm-bgcolor-skincolor
  a.ttm-icon
  i,
.featured-imagebox-services.style2:hover
  .featured-content.ttm-bgcolor-darkgrey
  a.ttm-icon
  i,
.ttm-blog-classic .ttm-blogbox-footer-readmore a:hover,
.team-media-block ul li a:hover,
.widget.widget-nav-menu ul li:hover a,
.widget.widget-nav-menu ul li.active a,
.error-404 a.ttm-btn:hover,
.widget .tagcloud a:hover,
.footer .social-icons li > a:hover,
.ttm-pagination .page-numbers.current,
.ttm-pagination .page-numbers:hover {
  color: #fff;
}

.ttm-topbar-wrapper {
  line-height: 49px;
  height: 50px;
}

.tooltip-top,
.tooltip-bottom {
  position: relative;
}

/* =============================================================== */
/* scial Icon */
/*  ===============================================
    SocialIcon
------------------------*/
.social-icons ul {
  margin: 0;
  padding: 0;
}
.social-icons {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
}
.social-icons li {
  display: inline-block;
  border: none;
  margin: 0 3px;
}
.social-icons.circle li > a,
.social-icons.square li > a {
  border: 1px solid #e8ecef;
  height: 33px;
  width: 33px;
  line-height: 33px;
  text-align: center;
  display: block;
  font-size: 13px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.social-icons.square li > a {
  border-radius: 0;
}
.ttm-bgcolor-darkgrey .social-icons.circle li > a,
.ttm-bgcolor-skincolor .social-icons.circle li > a {
  border: none;
  color: #2c2c2c;
}
.ttm-bgcolor-darkgrey .social-icons.circle li > a,
.ttm-bgcolor-skincolor .social-icons.circle li > a {
  background-color: #fff;
}

/*  ===============================================
    Header
------------------------*/

/** SiteBrand(logo) **/
.site-branding {
  height: 100px;
  width: 100%;
  line-height: 100px;
  position: relative;
  display: block;
  z-index: 1;
  text-align: center;
  position: absolute;
}
.ttm-header-top-wrapper {
  position: relative;
}
.site-branding img {
  max-height: 56px;
}
.ttm-header-style-01 .site-branding img {
  max-height: 110px;
  position: relative;
  top: 13px;
}
.ttm-header-style-01 .site-branding img.logo-shape {
  position: absolute;
  left: 0;
  right: 0;
  width: 216px;
  top: 99px;
  z-index: -1;
  margin: 0 auto;
}
.ttm-header-style-01 .fixed-header .site-branding img {
  top: 10px;
  max-height: 80px;
}
.ttm-header-style-01 .fixed-header #site-navigation .menu ul {
  margin-top: 0;
}
.ttm-header-style-01 .fixed-header .site-branding img.logo-shape {
  top: 60px;
}
/** SiteNavigation(Menu) **/
.site-navigation {
  position: relative;
  z-index: 2;
}
#site-header-menu #site-navigation .menu ul li ul > li:not(:last-child) {
  border-bottom: 1px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.04);
}
#site-header-menu #site-navigation .menu ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.ttm-menu-toggle {
  float: right;
}
.ttm-menu-toggle input[type="checkbox"] {
  display: none;
}
#site-header-menu #site-navigation .menu > ul > li {
  display: inline-block;
  position: relative;
  transition: all 0.5s;
}
#site-header-menu #site-navigation .menu ul li:hover > ul {
  opacity: 1;
  display: block;
  visibility: visible;
  height: auto;
  -webkit-transform: translate(0, 0px);
          transform: translate(0, 0px);
  border-top: 3px solid;
}
#site-header-menu #site-navigation .menu ul ul > li {
  position: relative;
}
#site-header-menu #site-navigation .menu ul ul ul {
  position: absolute;
  left: 100%;
  top: 0;
}
#site-header-menu #site-navigation .menu ul li > a {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  font-weight: 500;
}
#site-header-menu #site-navigation ul ul li {
  position: relative;
  border-bottom: 1px solid transparent;
}
#site-header-menu #site-navigation .menu ul li > ul li > a {
  margin: 0;
  display: block;
  padding: 15px 20px;
  position: relative;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
  height: auto;
  line-height: 18px;
}
#site-header-menu #site-navigation .menu ul > li > ul li.has-submenu > a:after {
  content: "\F105";
  position: absolute;
  background-color: transparent;
  right: 12px;
  top: 16px;
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin-left: 4px;
  opacity: 0.8;
}
#site-header-menu #site-navigation .menu ul li a span {
  padding: 1px 3px 2px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 7px;
}

/**  rs-slider Home banner  **/
#rev_slider_1_1_forcefullwidth .tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/**  ttm-search-overlay **/
.ttm-header-icon {
  position: relative;
}
.ttm-search-overlay {
  position: absolute;
  top: 100%;
  right: -24px;
  width: 400px;
  background: #fff;
  border-top: 3px solid;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all ease 0.3s;
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  z-index: 111;
}
.ttm-header-style-01 .ttm-search-overlay .w-search-form-row:before {
  right: 27px;
}
.ttm-search-overlay .w-search-form-row {
  display: table;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  position: relative;
  padding: 20px;
}
.ttm-search-overlay .w-search-form-row:before {
  position: absolute;
  right: 17px;
  top: -8px;
  width: 47px;
  height: 47px;
  text-align: center;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid;
  content: " ";
}
.ttm-search-overlay .ttm-site-searchform input[type="search"] {
  font-size: 14px;
  text-align: left;
  height: 45px;
  border: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none !important;
  color: #252525;
  width: 88%;
  line-height: 45px;
  float: left;
  padding: 9px 15px;
}
.ttm-search-overlay .ttm-site-searchform button {
  font-size: 14px;
  width: 12%;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: 45px;
  text-align: center;
  display: block;
  line-height: 50px;
  color: #fff;
  float: left;
}
.ttm-header-search-link .ttm-tectxon-icon-search:before {
  font-weight: 600;
}
.ttm-tectxon-icon-search:before {
  content: "\E825";
  font-family: "themify";
}
.ttm-search-overlay.st-show {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* .widget_info */
.widget_info {
  position: relative;
}
.widget_info:not(:last-child) {
  padding-right: 50px;
}
.widget_info .widget_icon {
  font-size: 34px;
}
.widget_info .widget_content {
  margin-left: 14px;
  position: relative;
}
.widget_info:not(:last-child) .widget_content:after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: rgba(243, 243, 243, 1);
  display: block;
  position: absolute;
  right: -25px;
  top: 0px;
}
.widget_info .widget_title {
  font-size: 17px;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 5px;
}
.widget_info .widget_desc {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 0;
}

/* Media Query */

@media only screen and (min-width: 1200px) {
  /*topbar*/
  .site-header-menu {
    background-color: rgba(255, 255, 255, 1);
    height: 100px;
  }
  #site-header-menu #site-navigation .menu ul > li > a {
    display: block;
    margin: 0px;
    padding: 0px 18px;
    text-decoration: none;
    position: relative;
    z-index: 1;
    height: 100px;
    line-height: 100px;
    text-transform: uppercase;
  }
  .ttm-header-style-01
    #site-header-menu
    #site-navigation
    .menu
    ul.dropdown
    > li
    > a:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #2d4a8a;
    height: 2px;
    transform-origin: right center;
    -webkit-transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -khtml-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85), -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  }
  #site-header-menu #site-navigation .menu > ul > li > ul > li a {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    transition: all 0.3s linear;
  }
  #site-header-menu #site-navigation .menu > ul > li ul a {
    transition: all 0.3s linear;
  }
  #site-header-menu #site-navigation .menu > ul > li ul a:hover:before {
    opacity: 1;
    left: 10px;
  }
  #site-header-menu #site-navigation .menu > ul > li:first-child {
    padding-left: 60px;
  }
  #site-header-menu #site-navigation .menu > ul > li.logo-after-this {
    margin-right: 280px;
  }
  .ttm-header-style-01 .ttm-header-icons:before,
  .ttm-header-style-classic .ttm-header-icons:before {
    display: block;
    content: "";
    position: absolute;
    height: 30px;
    width: 1px;
    left: 10px;
    top: 50%;
    margin-top: -14px;
    background-color: rgba(11, 12, 38, 0.06);
  }
  .ttm-header-style-01 .ttm-header-icons:before {
    background-color: rgba(38, 48, 69, 0.09);
  }
  .ttm-stickable-header.fixed-header {
    z-index: 111;
    background-color: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  }
  #site-header-menu #site-navigation .menu > ul > li ul a:before {
    font-size: 24px;
    line-height: 9px;
    content: "";
    margin-right: 5px;
    position: absolute;
    width: 6px;
    height: 2px;
    opacity: 0;
    left: 10px;
    transition: 0.5s all;
    top: 22px;
  }
  #site-header-menu #site-navigation .menu > ul > li ul a:hover:before {
    opacity: 1;
    left: 20px;
  }
  #site-header-menu #site-navigation .menu > ul ul li:hover > a {
    padding-left: 35px;
  }
  .ttm-header-style-01
    #site-header-menu
    .ttm-stickable-header.fixed-header
    #site-navigation
    > .menu
    > ul
    > li
    > a,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .site-branding,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .ttm-header-icons,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .ttm-header-icon,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .header-btn {
    height: 70px;
    line-height: 70px;
  }
  .fixed-header {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }
  .ttm-rt-contact,
  .ttm-header-icon,
  .header-btn,
  .ttm-custombutton {
    float: right;
  }

  /*ttm-header-style-01*/
  .ttm-header-style-01 .ttm-topbar-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  }
  .ttm-header-style-01 .site-branding,
  .ttm-header-style-01 #site-header-menu #site-navigation .menu > ul > li > a,
  .ttm-header-style-01 .ttm-header-icons,
  .ttm-header-style-01 .ttm-header-icon {
    height: 100px;
    line-height: 100px;
  }

  #site-header-menu #site-navigation .menu > ul > li:last-child:after {
    display: none;
  }
  #site-header-menu #site-navigation > ul li > a,
  #site-header-menu #site-navigation .menu ul > li ul li > a {
    margin: 0;
    display: block;
    padding: 15px 20px;
    position: relative;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
  }
  #site-header-menu #site-navigation .menu ul ul {
    text-align: left;
    position: absolute;
    visibility: hidden;
    display: block;
    opacity: 0;
    line-height: 14px;
    left: 0;
    top: auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    transition: all 0.2s ease;
    z-index: 99;
    background-color: #fff;
    width: 250px;
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px);
    transition: opacity 200ms ease-in, visibility 200ms ease-in, -webkit-transform 200ms ease-in;
    transition: opacity 200ms ease-in, transform 200ms ease-in,
      visibility 200ms ease-in;
    transition: opacity 200ms ease-in, transform 200ms ease-in,
      visibility 200ms ease-in, -webkit-transform 200ms ease-in;
  }
  #site-header-menu #site-navigation .menu > ul > li:last-child > ul {
    right: 0;
    left: auto;
  }
  #site-header-menu #site-navigation div.menu > ul > li:nth-child(2) ul {
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;
  }
  #site-header-menu #site-navigation > ul > li ul a:before {
    font-family: "FontAwesome";
    display: inline;
    text-decoration: inherit;
    text-align: center;
    font-size: 24px;
    line-height: 9px;
    content: "-";
    margin-right: 5px;
    -webkit-transform: translateY(-50%) translateX(-10px);
            transform: translateY(-50%) translateX(-10px);
    left: 0px;
    transition: 0.3s all;
  }

  li a:hover {
    color: #bf9456;
  }

  /*Footer*/
  .second-footer > div > .row .widget-area:first-child .widget {
    margin-right: 10px;
  }
  .second-footer
    > div
    > .row
    .widget-area:first-child
    .widget:not(:first-child) {
    padding: 0;
    margin-top: -20px;
  }
}
.collapse.show {
    display: block;
    height: 20rem;
}
@media only screen and (max-width: 767px) {
  /* Temporary rule to force visibility */
  #site-header-menu #site-navigation .menu ul li {
    display: block !important;
  }
}


.footer-layout1,
.footer-layout3 {
  background-color: #010f1c;
  --body-color: #fff;
  overflow: hidden;
  background-position: top center;
  background-size: 100% 100%;
}
.footer-wrapper {
  --border-color: rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 2;
}
.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  /* background-size: cover;
  background-position: center center; */
}
.widget-area {
  padding-top: 50px;
  padding-bottom:0px;
}
.footer-widget,
.footer-widget .widget {
  padding: 0px !important;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
  box-shadow: none;
}
.footer-widget .widget_title {
  max-width: 270px;
  color: #fff;
  border-bottom: none;
  margin: -0.12em 0 32px 0;
  padding: 0 0 42px 0;
}
.as-widget-contact {
  max-width: 265px;
}
.footer-widget {
  margin-bottom: 50px;
}
.info-box {
  display: flex;
  margin-bottom: 21px;
}
.info-box_icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  line-height: 29px;
  font-size: 14px;
  background-color: transparent;
  color: #dfb68d;
  border: 1px solid #dfb68d;
  border-radius: 999px;
  text-align: center;
  margin-right: 10px;
}
.info-box_text {
  display: block;
  color: #fff;
  margin-top: -0.45em;
  margin-bottom: 0;
}
.info-box_link {
  display: inline-block;
  color: #fff;
}
.copyright-wrap {
  padding: 20px 0 1px;
  background-size: 100% auto;
}
.as-widget-about .about-text {
  margin-bottom:0px;
  margin-top: 0rem;
}
.about-text {
  color: #fff;
}
.footer-widget.widget_meta .menu,
.footer-widget.widget_meta > ul,
.footer-widget.widget_pages .menu,
.footer-widget.widget_pages > ul,
.footer-widget.widget_archive .menu,
.footer-widget.widget_archive > ul,
.footer-widget.widget_categories .menu,
.footer-widget.widget_categories > ul,
.footer-widget.widget_nav_menu .menu,
.footer-widget.widget_nav_menu > ul {
  margin-bottom: -5px;
  padding-left: 0px;
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}
.footer-widget.widget_meta a,
.footer-widget.widget_pages a,
.footer-widget.widget_archive a,
.footer-widget.widget_categories a,
.footer-widget.widget_nav_menu a {
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 0 20px;
  margin-bottom: 21px;
  font-family: var(--body-font);
  display: block;
  max-width: 100%;
  width: -webkit-max-content;
  width: max-content;
  padding-right: 0;
  background-color: transparent;
  border-bottom: none;
  position: relative;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: block;
  border: none;
  margin: 0;
  padding: 15px 25px 15px 16px;
  font-size: 16px;
  line-height: 1.313;
  color: var(--body-color);
  position: relative;
  border-bottom: 1px solid var(--border-color);
}
.footer-wrapper .as-social a {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.as-social a {
  display: inline-block;
  width: 35px;
  height:35px;
  line-height: 35px;
  background-color: #fff;
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-right: 5px;
  border-radius: 50%;
}

.footer-layout1 .copyright-wrap,
.footer-layout3 .copyright-wrap {
  background-color: #050505;
}
.copyright-text a {
  color: #dfb68d;
  padding-left: 2px;
}
.copyright-text{
    color: white;
}

@media only screen and (max-width: 767px){
  .footer-wrapper{margin-bottom: 40px;}
  .mobile-fixed {
    display: block !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .appoint {
    width: 50%;
    background: #008000;
    padding: 10px;
    float: left;
    text-align: center;
    color: white;
    font-size: 18px;
    -webkit-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
  }
  .appoint a {
    color: white;
  }
  .appoint a:hover {
    color: white;
  }
  .call {
    border: none;
    background:#bf9456;
    text-align: center;
    color: white;
    padding: 10px;
    width: 50%;
    float: left;
    font-size: 18px;
    -webkit-animation: drift 3s linear infinite;
            animation: drift 3s linear infinite;
  }
  .call a {
    color: white;
  }
  .call a:hover {
    color: white;
  }
}



.banner-section{
    padding: 50px 0px 50px 0px;
    background: url('/assets/img/bandperformance.jpg') center fixed;
    position: relative !important;
    background-color: #00000099;
    color: white;
    background-blend-mode: overlay !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-repeat: no-repeat !important;
}

.about-section{padding: 50px 0px;}


/* @media (min-width: 1200px){
    .container {
        max-width: 1200px;
    }
} */
.row > * {
    padding-right: calc(var(--bs-gutter-x) * .6);
    padding-left: calc(var(--bs-gutter-x) * .6);
}
/* @media (min-width: 768px){
    .col-md-6 {
        flex: 0 0 auto;
    }    
} */
.contact-details {
    padding: 70px 50px 30px 0px;
}
.pbmit-heading-subheading {
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
}
.pbmit-heading-subheading .pbmit-subtitle {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 3px;
    margin-bottom: 10px;
    color: var(--pbmit-global-color);
    font-family: var(--pbmit-body-typography-font-family);
}
.contact-details p {
    margin-bottom: 35px;
}
.contact-details h5 {
    font-size: 18px;
    line-height: 20px;
}
.contact-details p {
    margin-bottom: 35px;
}
p {
    margin: 0px 0px 25px;
    font-weight: 500;
    font-size: 14px;
}
.contact-form {
    position: relative;
    padding-top: 70px;
    padding-bottom: 10px;
    margin-top: 19px;
}
::after, ::before {
    box-sizing: border-box;
}
.pbmit-heading-subheading {
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
}
::after, ::before {
    box-sizing: border-box;
}
form {
    display: block;
    margin-top: 0em;
}
.contact-form .form-control {
    background-color: var(--pbmit-white-color);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(119, 119, 119, 0.2);
    border-image: initial;
}
.form-control {
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;
    width: 100%;
    margin-bottom: 25px;
    background-color: rgba(255, 255, 255, 0);
    padding: 13px 20px;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.28);
    border-image: initial;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.w-100 {
    width: 100% !important;
}
.contact-form .pbmit-btn {
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.75;
    background-color: #bf9456;
    border: #bf9456;
    padding: 16px 34px;
}
@media (min-width: 768px){
    .contact-form {
        margin-top: 82px;
        padding: 23px 22px;
    }   
}
.video-widget iframe, .gmaps iframe {
    margin-top: 95px;
    left: 0;
    width: 100%;
    height: 550px;
}
iframe {
    border: 0;
}
.card{
    padding: 40px;
    margin-top: 50px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    border-radius: 20px;
}
.pbmit-tbar-title {
    padding-left:28rem;
    padding-top: 62px;
}
.contactus{
    padding: 50px 0px 50px 0px;
    background: url('/assets/img/livemusic.jpg') center fixed;
    position: relative !important;
    background-color: #00000099;
    color: white;
    background-blend-mode: overlay !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-repeat: no-repeat !important;
}
#contact-form{padding: 50px 0px;}
#contact-form .contact-area::before {
    content: "";
    height: calc(100% - 0px * 2);
    width: calc(100% + 60px * 2);
    position: absolute;
    top: 20px;
    left: calc(0px - 60px);
    border: 2px solid #dfb68d;
}
#contact-form .contact-img {
    height: 100%;
    position: relative;
    z-index: 2;
    top: 20px;
}
#contact-form .contact-area {
    --space: 60px;
    position: relative;
    padding: 15px;
}
.detail-section{padding:0px 0px 30px;}

#about {background: #ffffff;padding: 50px 0px 50px 0px;}
.welcome-data h2 {
  color: #bf9456;
  text-transform: capitalize;
  font-family: "Dancing Script", cursive;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 15px;
}
#about img {
  float: right;
  width: 320px;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.invitation-box {
  background: #fff;
  max-width:100%;
  padding:0px;
  box-shadow: 0px 3px 24px 0px rgb(0 0 0 / 6%);
  text-align: center;
  /* margin: 100px auto; */
  position: relative;
}
.invitation-box:before {
  content: "";
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: absolute;
  left: 38px;
  top: 30px;
  border: 5px solid rgb(191 148 86 / 50%);
}
.invitation-box .left-vec {
  background: url(https://themegeniuslab.com/html/jolly/assets/images/invitation-left-img.png)
    center center/cover no-repeat local;
  width: 220px;
  height: 414px;
  position: absolute;
  left: -150px;
  top: -100px;
  z-index: 0;
}
.invitation-box .right-vec {
  background: url(https://themegeniuslab.com/html/jolly/assets/images/invitation-right-image.png)
    center center/cover no-repeat local;
  width: 400px;
  height: 508px;
  position: absolute;
  right: -120px;
  bottom: -100px;
  z-index: 0;
}
.invitation-box .inner {
  position: relative;
}
.theme-btn,
.theme-btn-s3,
.theme-btn-s4 {
  background-color: #d2ae7c;
  color: #fff;
  padding: 15px 50px 15px 25px;
  font-weight: 600;
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.btnAbout:hover {
  background: transparent !important;
  border: 1px solid #bf9456 !important;
  text-decoration: none !important;
  color: #bf9456 !important;
  box-shadow: none !important;
}

.btnAbout {
  text-transform: capitalize;
  border-radius: 4px !important;
  padding: 10px 25px !important;
  background: #bf9456 !important;
  color: #ffffff !important;
  font-size: 16px;
  cursor: pointer;
  outline: none !important;
  border: 1px solid #bf9456 !important;
  transition: all 0.3s ease-out 0s;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 30%) !important;
}
/* Our Service Css */
.title-area .sec-title {
  margin-bottom: 55px;
}
.sec-title {
  margin-bottom: calc(var(--section-title-space) - 12px);
  margin-top: 0.77em;
}

#service-sec{background: url('https://img.freepik.com/premium-vector/seamless-white-gray-geometric-texture-smooth-design-vector-decorative-background_547648-2158.jpg?w=826');
  padding: 50px 0px;
}

.service-box {
  --space: 10px;
  position: relative;
  text-align: center;
  padding: var(--space);
  box-shadow: 0px 6px 30px rgba(1, 15, 28, 0.1);
  background-color: #ffffff;
  height: 300px;
}

.service-box_icon {
  font-size: 24px;
  line-height: 48px;
  color: #d2ae7c;
  width: 140px;
  height: 70px;
  background-size: 100% 100%;
  margin: 0 auto 15px auto;
  text-align: center;
}

.service-box_icon img {
  transition: 0.4s ease-in-out;
}

.service-box_title {
  font-size: 20px;
  line-height: 1.45;
  font-weight: 600;
  margin: 0 0 15px 0;
}

.service-box_title a {
  color: inherit;
}

.service-box_title a:hover {
  color: #d2ae7c;
}

.service-box_text {
  max-width: 296px;
  margin: 0 auto 24px auto;
  font-size: 14px;
}

.service-box_inner {
  background-size: 95% 95%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: 0.4s ease-in-out;
}

.service-box .line-btn:hover {
  color: var(--white-color);
}

.service-box .line-btn:hover:before {
  background-color: var(--white-color);
}

.service-box:hover .service-box_inner {
  background-color: var(--title-color);
}

.service-box:hover .service-box_title,
.service-box:hover .service-box_text {
  color: var(--white-color);
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.service-box_icon {
  font-size: 24px;
  line-height: 48px;
  color: #dfb68d;
  width: 140px;
  height: 70px;
  background-size: 100% 100%;
  margin: 0 auto 15px auto;
  text-align: center;
}

.service-grid_title a:hover {
  color: #010f1c;
}
.service-box:hover .service-box_inner {
  background-color: #010f1c;
}
.service-box:hover .service-box_title,
.service-box:hover .service-box_text {
  color: #fff;
}
.line-btn {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 4px;
  margin-bottom: -1px;
  text-transform: uppercase;
  color: #dfb68d;
}

@media (max-width: 375px) {
  .service-box_inner {
    padding: 30px 12px;
  }
}

/* banner */

/*banner*/
#banner {
  padding: 80px 0px 80px 0px;
  background: url("/assets/img/sectionBanner.jpg") center fixed;
  position: relative !important;
  background-color: #2a2a2ac9;
  background-blend-mode: overlay;
  position: relative !important;
  background-size: cover !important;
  overflow: hidden !important;
  background-repeat: no-repeat !important;
}
#banner h3 {
  text-transform: capitalize;
  font-family: "Dancing Script", cursive;
  font-size: 50px;
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
#banner h3 span {
  color: #c78665;
  font-family: "Dancing Script", cursive;
}
#banner p {
  color: #ffffff;
  text-transform: uppercase;
  font-family: SemiBoldfont;
  font-size: 24px;
  width: 700px;
  margin: 0 auto;
}

.banner-content {
  text-align: center;
}
.banner-content .btn {
  margin-top: 30px;
}
.banner-content .btn:hover {
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}
.banner-content .btn:focus {
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}
@media screen and (max-width: 767px) {
  #banner p {
    width: auto;
    margin: 0 auto;
    font-size: 18px;
  }
}

.banner-content a {
  margin-top: 150px !important;
}
/*banner end*/

/*services*/
#services {
  padding: 80px 0px 80px 0px;
  background: #f7f7f7;
}
#services .panel h3 {
  text-transform: uppercase;
  font-family: BoldFont;
  color: #2b2a28;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 0px;
  transition: all 0.3s ease-out 0s;
}
#services .panel {
  overflow: hidden;
  border: 1px solid #dddddd;
  padding: 0px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px !important;
  transition: all 0.2s ease-in-out;
}
#services .panel .panel-heading {
  overflow: hidden;
  border: none !important;
  padding: 0px;
  border-radius: 0px !important;
}
.service-data {
  text-align: center;
}
.service-data img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
}
#services .panel:hover img { /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}

#services .panel:hover h3 {
  color: #c78665;
}
#services .panel:hover {
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}
.second-row-services {
  padding-top: 30px;
}
@media screen and (max-width: 991px) {
  #services .col-xs-3 {
    width: 50%;
    margin-top: 20px;
  }
  .second-row-services {
    padding-top: 0px;
  }
}
@media screen and (max-width: 540px) {
  #services .col-xs-3 {
    width: 100%;
    margin-top: 20px;
  }
}

/* gallery */

.grid {
  -webkit-column-count: 4;
  column-count: 4;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0 50px;
}
.grid li {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  transition: all 0.5s ease;
}
.grid li:hover {
  cursor: pointer;
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}
.grid li img {
  width: 100%;
}
pt-bkg02 {
}

/* Contact form */

.bg-title {
  background-color: #010f1c !important;
}
.contact-area {
  --space: 60px;
  position: relative;
}
.contact-area::before {
  content: "";
  height: calc(100% - 60px * 2);
  width: calc(100% + 60px * 2);
  position: absolute;
  top: 60px;
  left: calc(0px - 60px);
  border: 2px solid #dfb68d;
}
.contact-area .shape {
  position: absolute;
  top: 0;
  left: -182px;
}
.contact-img {
  height: 100%;
  position: relative;
  z-index: 2;
}
.contact-img img {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 100%;
}

select,
.form-control,
.form-select,
textarea,
input {
  height: 56px;
  padding: 0 25px 0 25px;
  padding-right: 45px;
  border: 1px solid transparent;
  color: var(--body-color);
  background-color: var(--smoke-color);
  border-radius: 0;
  font-size: 16px;
  width: 100%;
  font-family: var(--body-font);
  transition: 0.4s ease-in-out;
}

select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  border-color: #d2ae7c;
  background-color: var(--smoke-color);
}

select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder, .form-control::-webkit-input-placeholder, .form-select::-webkit-input-placeholder, textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
  color: var(--body-color);
}

select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}

select.style2,
.form-control.style2,
.form-select.style2,
textarea.style2,
input.style2 {
  height: auto;
  border: none;
  color: #8b929c;
  border-bottom: 1px solid;
  padding: 0 0 10px 0;
  background-position: right 1px top 5px;
  background-size: 16px 16px;
  background-color: transparent;
}

select.style2 option,
.form-control.style2 option,
.form-select.style2 option,
textarea.style2 option,
input.style2 option {
  background-color: var(--title-color);
  color: #8b929c;
  padding: 2px 15px;
}

select.style2::-webkit-input-placeholder,
.form-control.style2::-webkit-input-placeholder,
.form-select.style2::-webkit-input-placeholder,
textarea.style2::-webkit-input-placeholder,
input.style2::-webkit-input-placeholder {
  color: #8b929c;
}

select.style2::-webkit-input-placeholder,
.form-control.style2::-webkit-input-placeholder,
.form-select.style2::-webkit-input-placeholder,
textarea.style2::-webkit-input-placeholder,
input.style2::-webkit-input-placeholder {
  color: #8b929c;
}

select.style2::-webkit-input-placeholder, .form-control.style2::-webkit-input-placeholder, .form-select.style2::-webkit-input-placeholder, textarea.style2::-webkit-input-placeholder, input.style2::-webkit-input-placeholder {
  color: #8b929c;
}

select.style2::placeholder,
.form-control.style2::placeholder,
.form-select.style2::placeholder,
textarea.style2::placeholder,
input.style2::placeholder {
  color: #8b929c;
}

.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.form-select.style2,
select.style2 {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87109 1.71094L5.71484 5.62109C5.56901 5.7487 5.41406 5.8125 5.25 5.8125C5.08594 5.8125 4.9401 5.7487 4.8125 5.62109L0.65625 1.71094C0.382812 1.40104 0.373698 1.09115 0.628906 0.78125C0.920573 0.507812 1.23047 0.498698 1.55859 0.753906L5.25 4.25391L8.96875 0.753906C9.27865 0.498698 9.57943 0.498698 9.87109 0.753906C10.1263 1.08203 10.1263 1.40104 9.87109 1.71094Z' fill='%238B929C'/%3E%3C/svg%3E");
}

textarea.form-control,
textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
}

textarea.form-control.style2,
textarea.style2 {
  min-height: 100px;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}

.form-group > i {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 19px;
  font-size: 16px;
  color: var(--body-color);
}

.form-group > i.fa-envelope {
  padding-top: 1px;
}

.form-group > i.fa-comment {
  margin-top: -2px;
}

.form-group > i.fa-chevron-down {
  width: 17px;
  background-color: var(--smoke-color);
}

.form-group.has-label > i {
  top: 50px;
}

[class*="col-"].form-group > i {
  right: calc((var(--bs-gutter-x) / 2) + 25px);
}

option:checked,
option:focus,
option:hover {
  background-color: #d2ae7c;
  color: var(--white-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type="checkbox"]:checked ~ label:before {
  content: "\F00C";
  color: #fff;
  background-color: #d2ae7c;
  border-color: #d2ae7c;
}

input[type="checkbox"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}

input[type="checkbox"] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type="checkbox"].style2 ~ label {
  color: #8b929c;
  padding-left: 23px;
  margin-bottom: -0.5em;
}

input[type="checkbox"].style2 ~ label:before {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #8b929c;
  height: 14px;
  width: 14px;
  line-height: 14px;
  border-radius: 3px;
  top: 6px;
}

input[type="checkbox"].style2:checked ~ label:before {
  color: #d2ae7c;
}

input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type="radio"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}

input[type="radio"] ~ label::before {
  content: "\F111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid #d2ae7c;
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  transition: all 0.2s ease;
}

input[type="radio"]:checked ~ label::before {
  border-color: #d2ae7c;
  background-color: #d2ae7c;
  color: var(--white-color);
}

label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}

textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right
    calc(0.375em + 0.8875rem);
}

.row.no-gutters > .form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}

.form-messages.mb-0 * {
  margin-bottom: 0;
}

.form-messages.success {
  color: var(--success-color);
  display: block;
}

.form-messages.error {
  color: var(--error-color);
  display: block;
}

.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}
.space {
  padding-top: 120px;
}
.rsvp-form .form-group {
  margin-bottom: 10px;
}
.select.style2,
.form-control.style2,
.form-select.style2,
textarea.style2,
input.style2 {
  height: auto;
  border: none;
  color: #8b929c;
  border-bottom: 1px solid;
  padding: 0 0 10px 20px;
  background-position: right 1px top 5px;
  background-size: 16px 16px;
  background-color: transparent;
}
select.style2 option,
.form-control.style2 option,
.form-select.style2 option,
textarea.style2 option,
input.style2 option {
  background-color: #010f1c;
  color: #8b929c;
  padding: 2px 15px;
}
a:hover {
  color: #0056b3;
  text-decoration: none !important;
}

.page-title{font-size: 30px!important;}


@media only screen and (max-width: 767px){
  .invitation-box .right-vec{display: none;}
  .invitation-box .left-vec {display: none;}
}
.space-extra,
.space-extra-bottom {
  padding-bottom: calc(120px - 30px);
}
.space,
.space-top {
  padding-top: 120px;
}
.page-single {
  margin-bottom: 30px;
}
.page-img {
  margin-bottom: 40px;
}
.page-title {
  margin-top: -0.22em;
  font-size: 40px;
  margin-bottom: 20px;
}
.service-process-wrap {
  background-color: #fff9f3;
  border: 1px solid #e0e0e0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  gap: 20px;
  padding: 30px;
}
.service-process {
  border-right: 1px solid #bdbdbd;
  padding-right: 10px px;
}
.service-process_icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px solid rgba(223, 182, 141, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 36px;
  text-align: center;
  border-radius: 50%;
  color: #dfb68d;
  margin-bottom: 15px;
}
.service-process_text {
  font-size: 14px;
  margin-bottom: -0.5em;
}
.sidebar-area {
  margin-bottom: -10px;
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
}
.widget {
  padding: 40px;
  /* background-color: #f2f2f2; */
  margin-bottom: 40px;
  position: relative;
}

.widget_banner {
  background-color: #010f1c;
  position: relative;
}
.widget_banner .widget_title {
  color: #ffffff;
}
.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 600;

  line-height: 1em;
  margin: -0.12em 0 28px 0;
  padding: 0 0 25px 0;
}
.widget_title:after {
  content: "";
  height: 17px;
  width: 100%;
  background-image: url("/assets/img/widget_title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.widget-banner .banner-text {
  margin-bottom: 23px;
  color: #ffffff;
}
.widget_banner .shape1 {
  position: absolute;
  bottom: 0;
  right: 0;
}

