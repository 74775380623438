.banner-section{
    padding: 50px 0px 50px 0px;
    background: url('/assets/img/bandperformance.jpg') center fixed;
    position: relative !important;
    background-color: #00000099;
    color: white;
    background-blend-mode: overlay !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-repeat: no-repeat !important;
}

.about-section{padding: 50px 0px;}

