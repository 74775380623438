.space-extra,
.space-extra-bottom {
  padding-bottom: calc(120px - 30px);
}
.space,
.space-top {
  padding-top: 120px;
}
.page-single {
  margin-bottom: 30px;
}
.page-img {
  margin-bottom: 40px;
}
.page-title {
  margin-top: -0.22em;
  font-size: 40px;
  margin-bottom: 20px;
}
.service-process-wrap {
  background-color: #fff9f3;
  border: 1px solid #e0e0e0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 30px;
}
.service-process {
  border-right: 1px solid #bdbdbd;
  padding-right: 10px px;
}
.service-process_icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px solid rgba(223, 182, 141, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 36px;
  text-align: center;
  border-radius: 50%;
  color: #dfb68d;
  margin-bottom: 15px;
}
.service-process_text {
  font-size: 14px;
  margin-bottom: -0.5em;
}
.sidebar-area {
  margin-bottom: -10px;
  position: sticky;
  top: 160px;
}
.widget {
  padding: 40px;
  /* background-color: #f2f2f2; */
  margin-bottom: 40px;
  position: relative;
}

.widget_banner {
  background-color: #010f1c;
  position: relative;
}
.widget_banner .widget_title {
  color: #ffffff;
}
.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 600;

  line-height: 1em;
  margin: -0.12em 0 28px 0;
  padding: 0 0 25px 0;
}
.widget_title:after {
  content: "";
  height: 17px;
  width: 100%;
  background-image: url("/assets/img/widget_title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.widget-banner .banner-text {
  margin-bottom: 23px;
  color: #ffffff;
}
.widget_banner .shape1 {
  position: absolute;
  bottom: 0;
  right: 0;
}
