/* @media (min-width: 1200px){
    .container {
        max-width: 1200px;
    }
} */
.row > * {
    padding-right: calc(var(--bs-gutter-x) * .6);
    padding-left: calc(var(--bs-gutter-x) * .6);
}
/* @media (min-width: 768px){
    .col-md-6 {
        flex: 0 0 auto;
    }    
} */
.contact-details {
    padding: 70px 50px 30px 0px;
}
.pbmit-heading-subheading {
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
}
.pbmit-heading-subheading .pbmit-subtitle {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 3px;
    margin-bottom: 10px;
    color: var(--pbmit-global-color);
    font-family: var(--pbmit-body-typography-font-family);
}
.contact-details p {
    margin-bottom: 35px;
}
.contact-details h5 {
    font-size: 18px;
    line-height: 20px;
}
.contact-details p {
    margin-bottom: 35px;
}
p {
    margin: 0px 0px 25px;
    font-weight: 500;
    font-size: 14px;
}
.contact-form {
    position: relative;
    padding-top: 70px;
    padding-bottom: 10px;
    margin-top: 19px;
}
::after, ::before {
    box-sizing: border-box;
}
.pbmit-heading-subheading {
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
}
::after, ::before {
    box-sizing: border-box;
}
form {
    display: block;
    margin-top: 0em;
}
.contact-form .form-control {
    background-color: var(--pbmit-white-color);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(119, 119, 119, 0.2);
    border-image: initial;
}
.form-control {
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;
    width: 100%;
    margin-bottom: 25px;
    background-color: rgba(255, 255, 255, 0);
    padding: 13px 20px;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.28);
    border-image: initial;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.w-100 {
    width: 100% !important;
}
.contact-form .pbmit-btn {
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.75;
    background-color: #bf9456;
    border: #bf9456;
    padding: 16px 34px;
}
@media (min-width: 768px){
    .contact-form {
        margin-top: 82px;
        padding: 23px 22px;
    }   
}
.video-widget iframe, .gmaps iframe {
    margin-top: 95px;
    left: 0;
    width: 100%;
    height: 550px;
}
iframe {
    border: 0;
}
.card{
    padding: 40px;
    margin-top: 50px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    border-radius: 20px;
}
.pbmit-tbar-title {
    padding-left:28rem;
    padding-top: 62px;
}
.contactus{
    padding: 50px 0px 50px 0px;
    background: url('/assets/img/livemusic.jpg') center fixed;
    position: relative !important;
    background-color: #00000099;
    color: white;
    background-blend-mode: overlay !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-repeat: no-repeat !important;
}
#contact-form{padding: 50px 0px;}
#contact-form .contact-area::before {
    content: "";
    height: calc(100% - 0px * 2);
    width: calc(100% + 60px * 2);
    position: absolute;
    top: 20px;
    left: calc(0px - 60px);
    border: 2px solid #dfb68d;
}
#contact-form .contact-img {
    height: 100%;
    position: relative;
    z-index: 2;
    top: 20px;
}
#contact-form .contact-area {
    --space: 60px;
    position: relative;
    padding: 15px;
}
.detail-section{padding:0px 0px 30px;}
