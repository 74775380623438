@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

#about {background: #ffffff;padding: 50px 0px 50px 0px;}
.welcome-data h2 {
  color: #bf9456;
  text-transform: capitalize;
  font-family: "Dancing Script", cursive;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 15px;
}
#about img {
  float: right;
  width: 320px;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.invitation-box {
  background: #fff;
  max-width:100%;
  padding:0px;
  box-shadow: 0px 3px 24px 0px rgb(0 0 0 / 6%);
  text-align: center;
  /* margin: 100px auto; */
  position: relative;
}
.invitation-box:before {
  content: "";
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: absolute;
  left: 38px;
  top: 30px;
  border: 5px solid rgb(191 148 86 / 50%);
}
.invitation-box .left-vec {
  background: url(https://themegeniuslab.com/html/jolly/assets/images/invitation-left-img.png)
    center center/cover no-repeat local;
  width: 220px;
  height: 414px;
  position: absolute;
  left: -150px;
  top: -100px;
  z-index: 0;
}
.invitation-box .right-vec {
  background: url(https://themegeniuslab.com/html/jolly/assets/images/invitation-right-image.png)
    center center/cover no-repeat local;
  width: 400px;
  height: 508px;
  position: absolute;
  right: -120px;
  bottom: -100px;
  z-index: 0;
}
.invitation-box .inner {
  position: relative;
}
.theme-btn,
.theme-btn-s3,
.theme-btn-s4 {
  background-color: #d2ae7c;
  color: #fff;
  padding: 15px 50px 15px 25px;
  font-weight: 600;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.btnAbout:hover {
  background: transparent !important;
  border: 1px solid #bf9456 !important;
  text-decoration: none !important;
  color: #bf9456 !important;
  box-shadow: none !important;
}

.btnAbout {
  text-transform: capitalize;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  border-radius: 4px !important;
  padding: 10px 25px !important;
  background: #bf9456 !important;
  color: #ffffff !important;
  font-size: 16px;
  cursor: pointer;
  outline: none !important;
  border: 1px solid #bf9456 !important;
  transition: all 0.3s ease-out 0s;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 30%) !important;
}
/* Our Service Css */
.title-area .sec-title {
  margin-bottom: 55px;
}
.sec-title {
  margin-bottom: calc(var(--section-title-space) - 12px);
  margin-top: 0.77em;
}

#service-sec{background: url('https://img.freepik.com/premium-vector/seamless-white-gray-geometric-texture-smooth-design-vector-decorative-background_547648-2158.jpg?w=826');
  padding: 50px 0px;
}

.service-box {
  --space: 10px;
  position: relative;
  text-align: center;
  padding: var(--space);
  box-shadow: 0px 6px 30px rgba(1, 15, 28, 0.1);
  background-color: #ffffff;
  height: 300px;
}

.service-box_icon {
  font-size: 24px;
  line-height: 48px;
  color: #d2ae7c;
  width: 140px;
  height: 70px;
  background-size: 100% 100%;
  margin: 0 auto 15px auto;
  text-align: center;
}

.service-box_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-box_title {
  font-size: 20px;
  line-height: 1.45;
  font-weight: 600;
  margin: 0 0 15px 0;
}

.service-box_title a {
  color: inherit;
}

.service-box_title a:hover {
  color: #d2ae7c;
}

.service-box_text {
  max-width: 296px;
  margin: 0 auto 24px auto;
  font-size: 14px;
}

.service-box_inner {
  background-size: 95% 95%;
  padding: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-box .line-btn:hover {
  color: var(--white-color);
}

.service-box .line-btn:hover:before {
  background-color: var(--white-color);
}

.service-box:hover .service-box_inner {
  background-color: var(--title-color);
}

.service-box:hover .service-box_title,
.service-box:hover .service-box_text {
  color: var(--white-color);
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.service-box_icon {
  font-size: 24px;
  line-height: 48px;
  color: #dfb68d;
  width: 140px;
  height: 70px;
  background-size: 100% 100%;
  margin: 0 auto 15px auto;
  text-align: center;
}

.service-grid_title a:hover {
  color: #010f1c;
}
.service-box:hover .service-box_inner {
  background-color: #010f1c;
}
.service-box:hover .service-box_title,
.service-box:hover .service-box_text {
  color: #fff;
}
.line-btn {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 4px;
  margin-bottom: -1px;
  text-transform: uppercase;
  color: #dfb68d;
}

@media (max-width: 375px) {
  .service-box_inner {
    padding: 30px 12px;
  }
}

/* banner */

/*banner*/
#banner {
  padding: 80px 0px 80px 0px;
  background: url("/assets/img/sectionBanner.jpg") center fixed;
  position: relative !important;
  background-color: #2a2a2ac9;
  background-blend-mode: overlay;
  position: relative !important;
  background-size: cover !important;
  overflow: hidden !important;
  background-repeat: no-repeat !important;
}
#banner h3 {
  text-transform: capitalize;
  font-family: "Dancing Script", cursive;
  font-size: 50px;
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
#banner h3 span {
  color: #c78665;
  font-family: "Dancing Script", cursive;
}
#banner p {
  color: #ffffff;
  text-transform: uppercase;
  font-family: SemiBoldfont;
  font-size: 24px;
  width: 700px;
  margin: 0 auto;
}

.banner-content {
  text-align: center;
}
.banner-content .btn {
  margin-top: 30px;
}
.banner-content .btn:hover {
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}
.banner-content .btn:focus {
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}
@media screen and (max-width: 767px) {
  #banner p {
    width: auto;
    margin: 0 auto;
    font-size: 18px;
  }
}

.banner-content a {
  margin-top: 150px !important;
}
/*banner end*/

/*services*/
#services {
  padding: 80px 0px 80px 0px;
  background: #f7f7f7;
}
#services .panel h3 {
  text-transform: uppercase;
  font-family: BoldFont;
  color: #2b2a28;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 0px;
  transition: all 0.3s ease-out 0s;
}
#services .panel {
  overflow: hidden;
  border: 1px solid #dddddd;
  padding: 0px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  border-radius: 0px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#services .panel .panel-heading {
  overflow: hidden;
  border: none !important;
  padding: 0px;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  border-radius: 0px !important;
}
.service-data {
  text-align: center;
}
.service-data img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#services .panel:hover img {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#services .panel:hover h3 {
  color: #c78665;
}
#services .panel:hover {
  box-shadow: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.second-row-services {
  padding-top: 30px;
}
@media screen and (max-width: 991px) {
  #services .col-xs-3 {
    width: 50%;
    margin-top: 20px;
  }
  .second-row-services {
    padding-top: 0px;
  }
}
@media screen and (max-width: 540px) {
  #services .col-xs-3 {
    width: 100%;
    margin-top: 20px;
  }
}

/* gallery */

.grid {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  column-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0 50px;
}
.grid li {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  transition: all 0.5s ease;
}
.grid li:hover {
  cursor: pointer;
  transform: scale(1.025);
}
.grid li img {
  width: 100%;
}
pt-bkg02 {
}

/* Contact form */

.bg-title {
  background-color: #010f1c !important;
}
.contact-area {
  --space: 60px;
  position: relative;
}
.contact-area::before {
  content: "";
  height: calc(100% - 60px * 2);
  width: calc(100% + 60px * 2);
  position: absolute;
  top: 60px;
  left: calc(0px - 60px);
  border: 2px solid #dfb68d;
}
.contact-area .shape {
  position: absolute;
  top: 0;
  left: -182px;
}
.contact-img {
  height: 100%;
  position: relative;
  z-index: 2;
}
.contact-img img {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 100%;
}

select,
.form-control,
.form-select,
textarea,
input {
  height: 56px;
  padding: 0 25px 0 25px;
  padding-right: 45px;
  border: 1px solid transparent;
  color: var(--body-color);
  background-color: var(--smoke-color);
  border-radius: 0;
  font-size: 16px;
  width: 100%;
  font-family: var(--body-font);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  border-color: #d2ae7c;
  background-color: var(--smoke-color);
}

select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}

select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}

select::-ms-input-placeholder,
.form-control::-ms-input-placeholder,
.form-select::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::-ms-input-placeholder {
  color: var(--body-color);
}

select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}

select.style2,
.form-control.style2,
.form-select.style2,
textarea.style2,
input.style2 {
  height: auto;
  border: none;
  color: #8b929c;
  border-bottom: 1px solid;
  padding: 0 0 10px 0;
  background-position: right 1px top 5px;
  background-size: 16px 16px;
  background-color: transparent;
}

select.style2 option,
.form-control.style2 option,
.form-select.style2 option,
textarea.style2 option,
input.style2 option {
  background-color: var(--title-color);
  color: #8b929c;
  padding: 2px 15px;
}

select.style2::-moz-placeholder,
.form-control.style2::-moz-placeholder,
.form-select.style2::-moz-placeholder,
textarea.style2::-moz-placeholder,
input.style2::-moz-placeholder {
  color: #8b929c;
}

select.style2::-webkit-input-placeholder,
.form-control.style2::-webkit-input-placeholder,
.form-select.style2::-webkit-input-placeholder,
textarea.style2::-webkit-input-placeholder,
input.style2::-webkit-input-placeholder {
  color: #8b929c;
}

select.style2:-ms-input-placeholder,
.form-control.style2:-ms-input-placeholder,
.form-select.style2:-ms-input-placeholder,
textarea.style2:-ms-input-placeholder,
input.style2:-ms-input-placeholder {
  color: #8b929c;
}

select.style2::-webkit-input-placeholder,
.form-control.style2::-webkit-input-placeholder,
.form-select.style2::-webkit-input-placeholder,
textarea.style2::-webkit-input-placeholder,
input.style2::-webkit-input-placeholder {
  color: #8b929c;
}

select.style2::-moz-placeholder,
.form-control.style2::-moz-placeholder,
.form-select.style2::-moz-placeholder,
textarea.style2::-moz-placeholder,
input.style2::-moz-placeholder {
  color: #8b929c;
}

select.style2:-ms-input-placeholder,
.form-control.style2:-ms-input-placeholder,
.form-select.style2:-ms-input-placeholder,
textarea.style2:-ms-input-placeholder,
input.style2:-ms-input-placeholder {
  color: #8b929c;
}

select.style2::-ms-input-placeholder,
.form-control.style2::-ms-input-placeholder,
.form-select.style2::-ms-input-placeholder,
textarea.style2::-ms-input-placeholder,
input.style2::-ms-input-placeholder {
  color: #8b929c;
}

select.style2::placeholder,
.form-control.style2::placeholder,
.form-select.style2::placeholder,
textarea.style2::placeholder,
input.style2::placeholder {
  color: #8b929c;
}

.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.form-select.style2,
select.style2 {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87109 1.71094L5.71484 5.62109C5.56901 5.7487 5.41406 5.8125 5.25 5.8125C5.08594 5.8125 4.9401 5.7487 4.8125 5.62109L0.65625 1.71094C0.382812 1.40104 0.373698 1.09115 0.628906 0.78125C0.920573 0.507812 1.23047 0.498698 1.55859 0.753906L5.25 4.25391L8.96875 0.753906C9.27865 0.498698 9.57943 0.498698 9.87109 0.753906C10.1263 1.08203 10.1263 1.40104 9.87109 1.71094Z' fill='%238B929C'/%3E%3C/svg%3E");
}

textarea.form-control,
textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
}

textarea.form-control.style2,
textarea.style2 {
  min-height: 100px;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}

.form-group > i {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 19px;
  font-size: 16px;
  color: var(--body-color);
}

.form-group > i.fa-envelope {
  padding-top: 1px;
}

.form-group > i.fa-comment {
  margin-top: -2px;
}

.form-group > i.fa-chevron-down {
  width: 17px;
  background-color: var(--smoke-color);
}

.form-group.has-label > i {
  top: 50px;
}

[class*="col-"].form-group > i {
  right: calc((var(--bs-gutter-x) / 2) + 25px);
}

option:checked,
option:focus,
option:hover {
  background-color: #d2ae7c;
  color: var(--white-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type="checkbox"]:checked ~ label:before {
  content: "\f00c";
  color: #fff;
  background-color: #d2ae7c;
  border-color: #d2ae7c;
}

input[type="checkbox"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}

input[type="checkbox"] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type="checkbox"].style2 ~ label {
  color: #8b929c;
  padding-left: 23px;
  margin-bottom: -0.5em;
}

input[type="checkbox"].style2 ~ label:before {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #8b929c;
  height: 14px;
  width: 14px;
  line-height: 14px;
  border-radius: 3px;
  top: 6px;
}

input[type="checkbox"].style2:checked ~ label:before {
  color: #d2ae7c;
}

input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type="radio"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}

input[type="radio"] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid #d2ae7c;
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="radio"]:checked ~ label::before {
  border-color: #d2ae7c;
  background-color: #d2ae7c;
  color: var(--white-color);
}

label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}

textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right
    calc(0.375em + 0.8875rem);
}

.row.no-gutters > .form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}

.form-messages.mb-0 * {
  margin-bottom: 0;
}

.form-messages.success {
  color: var(--success-color);
  display: block;
}

.form-messages.error {
  color: var(--error-color);
  display: block;
}

.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}
.space {
  padding-top: 120px;
}
.rsvp-form .form-group {
  margin-bottom: 10px;
}
.select.style2,
.form-control.style2,
.form-select.style2,
textarea.style2,
input.style2 {
  height: auto;
  border: none;
  color: #8b929c;
  border-bottom: 1px solid;
  padding: 0 0 10px 20px;
  background-position: right 1px top 5px;
  background-size: 16px 16px;
  background-color: transparent;
}
select.style2 option,
.form-control.style2 option,
.form-select.style2 option,
textarea.style2 option,
input.style2 option {
  background-color: #010f1c;
  color: #8b929c;
  padding: 2px 15px;
}
a:hover {
  color: #0056b3;
  text-decoration: none !important;
}

.page-title{font-size: 30px!important;}


@media only screen and (max-width: 767px){
  .invitation-box .right-vec{display: none;}
  .invitation-box .left-vec {display: none;}
}