header {
  display: block;
  position: sticky;
  top: 0px;
  z-index: 100;
}

/* top Bar */

/*  ===============================================
    TopBar
------------------------*/

.ttm-bgcolor-darkgrey {
  background-color: #252525;
}
.ttm-textcolor-white {
  color: white;
}
.ttm-topbar-wrapper {
  line-height: 49px;
  height: 50px;
}
.header-btn {
  background-color: #bf9456;
}
.header-btn .ttm-btn.ttm-btn-size-md {
  padding: 16px 25px 14px 25px;
  font-size: 13px;
  vertical-align: top;
  line-height: 20px;
  font-weight: 500;
  transition: all 0.5s;
  display: block;
}
.header-btn .ttm-btn.ttm-btn-size-md:before {
  content: none;
}
.ttm-topbar-wrapper .top-contact.text-left {
  position: relative;
  float: left;
  line-height: 50px;
}
.top-contact li {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.top-contact li:first-child {
  padding-left: 0px !important;
}
.top-contact li strong {
  font-weight: 500;
}
.top-contact {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
  display: inline-block;
  position: relative;
}
.ttm-topbar-content .top-contact li:before {
  content: "";
  height: 18px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.09);
  display: block;
  position: absolute;
  right: -3px;
  top: 17px;
}
.ttm-topbar-content .top-contact li:last-child:before {
  width: 0px;
}
.topbar-right .top-contact.ttm-highlight-right li:last-child:after {
  content: unset;
}
.ttm-topbar-wrapper .ttm-social-links-wrapper {
  margin: 0;
  position: relative;
  padding-left: 5px;
  padding-right: 15px;
}
.ttm-topbar-wrapper .social-icons li {
  margin: 0 3px;
}
.ttm-topbar-wrapper .social-icons li > a {
  display: block;
  border: none;
  width: 17px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  font-weight: 500;
  background-color: transparent;
  text-align: center;
}
.ttm-topbar-wrapper .social-icons li > a:hover {
  color: #bf9456 !important;
}
.ttm-topbar-content .topbar-right .list-inline {
  display: inline-block;
  line-height: 48px;
}
.top-contact.ttm-highlight-left {
  margin-right: 15px;
  padding-right: 5px;
}
.top-contact.ttm-highlight-right {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
}
.top-contact.ttm-highlight-left:after,
.top-contact.ttm-highlight-right:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 5000px;
  height: 100%;
}
.top-contact.ttm-highlight-right:after {
  right: 0;
}
.top-contact.ttm-highlight-right:after {
  left: 0;
}
.top-contact strong {
  font-weight: 600;
}
.top-contact i {
  margin-right: 7px;
  font-size: 14px;
  width: 15px;
  height: 15px;
  text-align: center;
  color: #bf9456;
}
.text-left {
  text-align: left !important;
}

.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-skincolor,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-dark:hover,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-skincolor,
.ttm-tabs.tabs-style-01 ul.tabs li.active,
.ttm-tabs.tabs-style-01 ul.tabs li:hover,
#site-header-menu #site-navigation .menu ul li:hover > ul,
.widget .tagcloud a:hover,
.ttm-blog-classic .post-meta,
.ttm-btn.ttm-btn-color-darkgrey:hover,
.ttm-btn.ttm-btn-color-white:hover,
.featured-icon-box.icon-align-top-content.style1 .ttm-border-seperator:before,
.featured-icon-box.style8 .ttm-icon.ttm-icon_element-size-xs,
.featured-imagebox-team.style1 .featured-content {
  border-color: #bf9456;
}
.ttm-bgcolor-skincolor,
.ttm-btn-bgcolor-skincolor,
.ttm-bgcolor-skincolor > .ttm-bg-layer,
.ttm-bgcolor-skincolor > .ttm-bg-layer > .ttm-col-wrapper-bg-layer-inner,
.ttm-icon_element-fill.ttm-icon_element-color-skincolor,
.ttm-btn-style-fill.ttm-btn-color-skincolor,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:not(.btn-inline):hover:after,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-dark:not(.btn-inline):hover:after,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-grey:not(.btn-inline):after,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-grey:not(.btn-inline):after,
.ttm-bgcolor-darkgrey .tooltip:after,
.ttm-bgcolor-darkgrey [data-tooltip]:after,
.ttm-bgcolor-grey .tooltip:after,
.ttm-bgcolor-grey [data-tooltip]:after,
.ttm-tabs.tabs-style-01 ul.tabs li.active a,
.ttm-tabs.tabs-style-01 ul.tabs li:hover a,
.ttm-tabs.tabs-style-01 ul.tabs li.active,
.ttm-blogbox-footer-readmore,
.ttm-blog-classic .ttm-postcategory .ttm-meta-line a,
.widget .tagcloud a:hover,
.footer .social-icons li > a:hover,
.ttm-pagination .page-numbers.current,
.ttm-pagination .page-numbers:hover,
.featured-imagebox-services.style2:hover
  .featured-content.ttm-bgcolor-darkgrey
  a.ttm-icon,
.tooltip:after,
[data-tooltip]:after,
#totop.top-visible,
.ttm-blog-classic .post-meta .ttm-meta-line:not(:last-child):after,
.ttm-blog-single-content .post-meta .ttm-meta-line:not(:last-child):after,
.team-media-block ul li a:hover,
.ttm-btn:not(.btn-inline):before,
.ttm-btn.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:hover:before,
.ttm-list.ttm-list-style-icon.style1 li:after,
.ttm-list.ttm-list-style-icon.style3 li:before,
.featured-icon-box.icon-align-top-content.style1 .ttm-border-seperator:after,
.featured-icon-box.icon-align-top-content.style1 .ttm-service-icon-dots:before,
.featured-imagebox-services.style1 a.di_link,
.featured-thumbnail .featured-thumbnail-inner:before,
.ttm-progress-bar .progress-bar,
.testimonials.style1 .testimonial-img:before,
.testimonials.style2 .testimonial-img:before,
.widget.widget-nav-menu ul li:hover a,
.widget.widget-nav-menu ul li.active a,
#site-header-menu #site-navigation .menu > ul > li ul a:before {
  background-color: #bf9456;
}
.ttm-textcolor-white,
.ttm-bgcolor-skincolor a,
.ttm-textcolor-white a,
.ttm-btn-color-white,
.ttm-icon_element-color-white,
.ttm-bgcolor-skincolor i,
.ttm-textcolor-white h1,
.ttm-textcolor-white h2,
.ttm-textcolor-white h3,
.ttm-textcolor-white h4,
.ttm-textcolor-white h5,
.ttm-textcolor-white h6,
.ttm-textcolor-white a,
.ttm-bgcolor-skincolor h1,
.ttm-bgcolor-skincolor h2,
.ttm-bgcolor-skincolor h3,
.ttm-bgcolor-skincolor h4,
.ttm-bgcolor-skincolor h5,
.ttm-bgcolor-skincolor h6,
.ttm-bgcolor-skincolor a,
.ttm-bgcolor-darkgrey h1,
.ttm-bgcolor-darkgrey > h2,
.ttm-bgcolor-darkgrey h3,
.ttm-bgcolor-darkgrey h4,
.ttm-bgcolor-darkgrey > h5,
.ttm-bgcolor-darkgrey h6,
.ttm-bgcolor-darkgrey a,
.ttm-textcolor-white .ttm-social-links-wrapper ul li a,
.ttm-icon_element-fill.ttm-icon_element-color-skincolor,
.ttm-icon_element-fill.ttm-icon_element-color-darkgrey,
.ttm-btn-style-fill.ttm-btn-color-skincolor,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-grey:hover,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-dark:hover,
.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:hover,
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-white:hover,
.footer h4.ttm-textcolor-white,
.page-title-heading h1,
.breadcrumb-wrapper span,
.breadcrumb-wrapper span a,
.ttm-tabs.tabs-style-01 ul.tabs li.active a,
.ttm-tabs.tabs-style-01 ul.tabs li:hover a,
.ttm-blogbox-footer-readmore,
.ttm-blog-classic .ttm-postcategory .ttm-meta-line a,
.featured-imagebox-services.style2:hover
  .featured-content.ttm-bgcolor-skincolor
  a.ttm-icon
  i,
.featured-imagebox-services.style2:hover
  .featured-content.ttm-bgcolor-darkgrey
  a.ttm-icon
  i,
.ttm-blog-classic .ttm-blogbox-footer-readmore a:hover,
.team-media-block ul li a:hover,
.widget.widget-nav-menu ul li:hover a,
.widget.widget-nav-menu ul li.active a,
.error-404 a.ttm-btn:hover,
.widget .tagcloud a:hover,
.footer .social-icons li > a:hover,
.ttm-pagination .page-numbers.current,
.ttm-pagination .page-numbers:hover {
  color: #fff;
}

.ttm-topbar-wrapper {
  line-height: 49px;
  height: 50px;
}

.tooltip-top,
.tooltip-bottom {
  position: relative;
}

/* =============================================================== */
/* scial Icon */
/*  ===============================================
    SocialIcon
------------------------*/
.social-icons ul {
  margin: 0;
  padding: 0;
}
.social-icons {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
}
.social-icons li {
  display: inline-block;
  border: none;
  margin: 0 3px;
}
.social-icons.circle li > a,
.social-icons.square li > a {
  border: 1px solid #e8ecef;
  height: 33px;
  width: 33px;
  line-height: 33px;
  text-align: center;
  display: block;
  font-size: 13px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.social-icons.square li > a {
  border-radius: 0;
}
.ttm-bgcolor-darkgrey .social-icons.circle li > a,
.ttm-bgcolor-skincolor .social-icons.circle li > a {
  border: none;
  color: #2c2c2c;
}
.ttm-bgcolor-darkgrey .social-icons.circle li > a,
.ttm-bgcolor-skincolor .social-icons.circle li > a {
  background-color: #fff;
}

/*  ===============================================
    Header
------------------------*/

/** SiteBrand(logo) **/
.site-branding {
  height: 100px;
  width: 100%;
  line-height: 100px;
  position: relative;
  display: block;
  z-index: 1;
  text-align: center;
  position: absolute;
}
.ttm-header-top-wrapper {
  position: relative;
}
.site-branding img {
  max-height: 56px;
}
.ttm-header-style-01 .site-branding img {
  max-height: 110px;
  position: relative;
  top: 13px;
}
.ttm-header-style-01 .site-branding img.logo-shape {
  position: absolute;
  left: 0;
  right: 0;
  width: 216px;
  top: 99px;
  z-index: -1;
  margin: 0 auto;
}
.ttm-header-style-01 .fixed-header .site-branding img {
  top: 10px;
  max-height: 80px;
}
.ttm-header-style-01 .fixed-header #site-navigation .menu ul {
  margin-top: 0;
}
.ttm-header-style-01 .fixed-header .site-branding img.logo-shape {
  top: 60px;
}
/** SiteNavigation(Menu) **/
.site-navigation {
  position: relative;
  z-index: 2;
}
#site-header-menu #site-navigation .menu ul li ul > li:not(:last-child) {
  border-bottom: 1px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.04);
}
#site-header-menu #site-navigation .menu ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.ttm-menu-toggle {
  float: right;
}
.ttm-menu-toggle input[type="checkbox"] {
  display: none;
}
#site-header-menu #site-navigation .menu > ul > li {
  display: inline-block;
  position: relative;
  transition: all 0.5s;
}
#site-header-menu #site-navigation .menu ul li:hover > ul {
  opacity: 1;
  display: block;
  visibility: visible;
  height: auto;
  transform: translate(0, 0px);
  border-top: 3px solid;
}
#site-header-menu #site-navigation .menu ul ul > li {
  position: relative;
}
#site-header-menu #site-navigation .menu ul ul ul {
  position: absolute;
  left: 100%;
  top: 0;
}
#site-header-menu #site-navigation .menu ul li > a {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  font-weight: 500;
}
#site-header-menu #site-navigation ul ul li {
  position: relative;
  border-bottom: 1px solid transparent;
}
#site-header-menu #site-navigation .menu ul li > ul li > a {
  margin: 0;
  display: block;
  padding: 15px 20px;
  position: relative;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
  height: auto;
  line-height: 18px;
}
#site-header-menu #site-navigation .menu ul > li > ul li.has-submenu > a:after {
  content: "\f105";
  position: absolute;
  background-color: transparent;
  right: 12px;
  top: 16px;
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin-left: 4px;
  opacity: 0.8;
}
#site-header-menu #site-navigation .menu ul li a span {
  padding: 1px 3px 2px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 7px;
}

/**  rs-slider Home banner  **/
#rev_slider_1_1_forcefullwidth .tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/**  ttm-search-overlay **/
.ttm-header-icon {
  position: relative;
}
.ttm-search-overlay {
  position: absolute;
  top: 100%;
  right: -24px;
  width: 400px;
  background: #fff;
  border-top: 3px solid;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  z-index: 111;
}
.ttm-header-style-01 .ttm-search-overlay .w-search-form-row:before {
  right: 27px;
}
.ttm-search-overlay .w-search-form-row {
  display: table;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  position: relative;
  padding: 20px;
}
.ttm-search-overlay .w-search-form-row:before {
  position: absolute;
  right: 17px;
  top: -8px;
  width: 47px;
  height: 47px;
  text-align: center;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid;
  content: " ";
}
.ttm-search-overlay .ttm-site-searchform input[type="search"] {
  font-size: 14px;
  text-align: left;
  height: 45px;
  border: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none !important;
  color: #252525;
  width: 88%;
  line-height: 45px;
  float: left;
  padding: 9px 15px;
}
.ttm-search-overlay .ttm-site-searchform button {
  font-size: 14px;
  width: 12%;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: 45px;
  text-align: center;
  display: block;
  line-height: 50px;
  color: #fff;
  float: left;
}
.ttm-header-search-link .ttm-tectxon-icon-search:before {
  font-weight: 600;
}
.ttm-tectxon-icon-search:before {
  content: "\e825";
  font-family: "themify";
}
.ttm-search-overlay.st-show {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* .widget_info */
.widget_info {
  position: relative;
}
.widget_info:not(:last-child) {
  padding-right: 50px;
}
.widget_info .widget_icon {
  font-size: 34px;
}
.widget_info .widget_content {
  margin-left: 14px;
  position: relative;
}
.widget_info:not(:last-child) .widget_content:after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: rgba(243, 243, 243, 1);
  display: block;
  position: absolute;
  right: -25px;
  top: 0px;
}
.widget_info .widget_title {
  font-size: 17px;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 5px;
}
.widget_info .widget_desc {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 0;
}

/* Media Query */

@media only screen and (min-width: 1200px) {
  /*topbar*/
  .site-header-menu {
    background-color: rgba(255, 255, 255, 1);
    height: 100px;
  }
  #site-header-menu #site-navigation .menu ul > li > a {
    display: block;
    margin: 0px;
    padding: 0px 18px;
    text-decoration: none;
    position: relative;
    z-index: 1;
    height: 100px;
    line-height: 100px;
    text-transform: uppercase;
  }
  .ttm-header-style-01
    #site-header-menu
    #site-navigation
    .menu
    ul.dropdown
    > li
    > a:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #2d4a8a;
    height: 2px;
    transform-origin: right center;
    -webkit-transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -khtml-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    -khtml-transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    -moz-transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    -ms-transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    -o-transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  }
  #site-header-menu #site-navigation .menu > ul > li > ul > li a {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  #site-header-menu #site-navigation .menu > ul > li ul a {
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  #site-header-menu #site-navigation .menu > ul > li ul a:hover:before {
    opacity: 1;
    left: 10px;
  }
  #site-header-menu #site-navigation .menu > ul > li:first-child {
    padding-left: 60px;
  }
  #site-header-menu #site-navigation .menu > ul > li.logo-after-this {
    margin-right: 280px;
  }
  .ttm-header-style-01 .ttm-header-icons:before,
  .ttm-header-style-classic .ttm-header-icons:before {
    display: block;
    content: "";
    position: absolute;
    height: 30px;
    width: 1px;
    left: 10px;
    top: 50%;
    margin-top: -14px;
    background-color: rgba(11, 12, 38, 0.06);
  }
  .ttm-header-style-01 .ttm-header-icons:before {
    background-color: rgba(38, 48, 69, 0.09);
  }
  .ttm-stickable-header.fixed-header {
    z-index: 111;
    background-color: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  }
  #site-header-menu #site-navigation .menu > ul > li ul a:before {
    font-size: 24px;
    line-height: 9px;
    content: "";
    margin-right: 5px;
    position: absolute;
    width: 6px;
    height: 2px;
    opacity: 0;
    left: 10px;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    transition: 0.5s all;
    top: 22px;
  }
  #site-header-menu #site-navigation .menu > ul > li ul a:hover:before {
    opacity: 1;
    left: 20px;
  }
  #site-header-menu #site-navigation .menu > ul ul li:hover > a {
    padding-left: 35px;
  }
  .ttm-header-style-01
    #site-header-menu
    .ttm-stickable-header.fixed-header
    #site-navigation
    > .menu
    > ul
    > li
    > a,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .site-branding,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .ttm-header-icons,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .ttm-header-icon,
  .ttm-header-style-01 .ttm-stickable-header.fixed-header .header-btn {
    height: 70px;
    line-height: 70px;
  }
  .fixed-header {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }
  .ttm-rt-contact,
  .ttm-header-icon,
  .header-btn,
  .ttm-custombutton {
    float: right;
  }

  /*ttm-header-style-01*/
  .ttm-header-style-01 .ttm-topbar-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  }
  .ttm-header-style-01 .site-branding,
  .ttm-header-style-01 #site-header-menu #site-navigation .menu > ul > li > a,
  .ttm-header-style-01 .ttm-header-icons,
  .ttm-header-style-01 .ttm-header-icon {
    height: 100px;
    line-height: 100px;
  }

  #site-header-menu #site-navigation .menu > ul > li:last-child:after {
    display: none;
  }
  #site-header-menu #site-navigation > ul li > a,
  #site-header-menu #site-navigation .menu ul > li ul li > a {
    margin: 0;
    display: block;
    padding: 15px 20px;
    position: relative;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
  }
  #site-header-menu #site-navigation .menu ul ul {
    text-align: left;
    position: absolute;
    visibility: hidden;
    display: block;
    opacity: 0;
    line-height: 14px;
    left: 0;
    top: auto;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    transition: all 0.2s ease;
    z-index: 99;
    background-color: #fff;
    width: 250px;
    transform: translate(0, 10px);
    transition: opacity 200ms ease-in, transform 200ms ease-in,
      visibility 200ms ease-in;
  }
  #site-header-menu #site-navigation .menu > ul > li:last-child > ul {
    right: 0;
    left: auto;
  }
  #site-header-menu #site-navigation div.menu > ul > li:nth-child(2) ul {
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;
  }
  #site-header-menu #site-navigation > ul > li ul a:before {
    font-family: "FontAwesome";
    display: inline;
    text-decoration: inherit;
    text-align: center;
    font-size: 24px;
    line-height: 9px;
    content: "-";
    margin-right: 5px;
    transform: translateY(-50%) translateX(-10px);
    left: 0px;
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
  }

  li a:hover {
    color: #bf9456;
  }

  /*Footer*/
  .second-footer > div > .row .widget-area:first-child .widget {
    margin-right: 10px;
  }
  .second-footer
    > div
    > .row
    .widget-area:first-child
    .widget:not(:first-child) {
    padding: 0;
    margin-top: -20px;
  }
}
.collapse.show {
    display: block;
    height: 20rem;
}
@media only screen and (max-width: 767px) {
  /* Temporary rule to force visibility */
  #site-header-menu #site-navigation .menu ul li {
    display: block !important;
  }
}

